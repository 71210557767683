import axios from "axios";
const authStore = {
    state: {
        error: {
            title: '',
            body: '',
            type: ''
        },
        user: {
            auth: {
                token: localStorage.getItem('auth_token') || null,
                expiresAt: localStorage.getItem('expires_at') || null,
                tokenType: localStorage.getItem('token_type') || null,
                authType: localStorage.getItem('auth_type') || null
            },
            permissions: localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')) : ({
                routePermissions: [],
                actionPermissions: []
            }),
            data: localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')) : null
        },
        errors: {},
        success: '',
        loading: false
    },
    getters: {
        isAuthenticated: state => {
            return state.user.auth.token != null;
        },
        isKinAuthenticated: state => {
            return state.user.auth.token != null && state.user.auth.authType == 0;
        },
        isMfgAuthenticated: state => {
            return state.user.auth.token != null && state.user.auth.authType == 1;
        },
        authType: state => {
            return state.user.auth.authType;
        },
        authData: state => {
            return state.user.auth;
        },
        authUser: state => {
            return state.user.data;
        },
        authPermissions: state => {
            return state.user.permissions;
        },
        errorObj: state => {
            return state.error;
        }
    },
    mutations: {
        setAuthData(state, payload) {
            state.user.auth = {
                isLoggedIn: true,
                token: payload.token,
                expiresAt: payload.expiresAt,
                tokenType: payload.tokenType,
                authType: payload.authType
            }
        },
        setUserData(state, payload) {
            state.user.data = {...payload.userData}
            state.user.permissions = {...payload.permissions} 
        },
        resetUser(state) {
            state.user = {
                auth: {
                    token: null,
                    expiresAt: null,
                    tokenType: null,
                    authType: null,
                },
                permission: {},
                data: null
            }
        },
        showError(state, payload) {
            state.error = {
                title: payload ? payload.title : '',
                body: payload ? payload.body : '',
                type: payload ? payload.type : 'info'
            }
        }
    },
    actions: {
        async fetchUserData(context, payload) {
            const headers = {};
            if ('token' in payload) {
                headers['Authorization'] = `Bearer ${payload.token}`
            }
            return axios({
                method: 'get',
                url: 'users/me',
                headers,
            }).then(res => {
                if (res.status == 200) {
                    const currentDateTime = new Date();
                    const jwtExpiryDate = currentDateTime.getTime() + (60*60*1000);
                    if ('storeToken' in payload && payload.storeToken) {
                        if (payload.switchUser) {
                            localStorage.setItem('switch_user', 1);
                        } else {
                            localStorage.removeItem('switch_user');
                        }
                        localStorage.setItem('auth_token', payload.token);
                        localStorage.setItem('expires_at', new Date(jwtExpiryDate));
                        localStorage.setItem('token_type', 'bearer');
                        localStorage.setItem('auth_type', 0);
                        context.commit('setAuthData', {token: payload.token, tokenType: payload.tokenType, expiresAt: payload.expiresAt, authType: 0})
                        // Set global header in axios for token authentication
                        axios.defaults.headers.common['Authorization'] = `Bearer ${payload.token}`;
                        // Store user information and permissions in the system
                        const permissions = [...res.data.permissions];
                        const routePermissions = [];
                        const actionPermissions = [];
                        permissions.map(permission => {
                            routePermissions.push(permission.frontend_route_name);
                            actionPermissions.push(permission.permission_code);
                        });
                        const userData = {
                            first_name:res.data.first_name,
                            last_name:res.data.last_name,
                            email:res.data.email,
                            status:res.data.status,
                            photo:res.data.photo,
                            color:res.data.color,
                            id:res.data.id,
                            logout_user_flag:res.data.logout_user_flag,
                            role:res.data.role,
                            department: res.data.department,
                            department_id: res.data.department_id,
                            role_id: res.data.role_id,
                            warehouseOptions: res.data.warehouseOptions,
                            kn_warehouse_id: res.data.kn_warehouse_id,
                            warehouse_confirmation_flag: res.data.warehouse_confirmation_flag
                        }
                        context.commit('setUserData', {userData, permissions: {routePermissions, actionPermissions}})
                        localStorage.setItem('user', JSON.stringify(userData))
                        localStorage.setItem('permissions', JSON.stringify({routePermissions, actionPermissions}))
                    }
                    return true;
                } else {
                    return false;
                }
            }).catch(() => {
                
            })
        },
        logoutUser(context) {
            localStorage.clear();
            context.commit('resetUser')
            delete axios.defaults.headers.common['Authorization']
            window.location.replace(`${process.env.VUE_APP_AUTH_URL}/logout?return_url=${process.env.VUE_APP_URL}`)
            return true
        },
        async checkAuth(context) {
            return axios({
                method: 'get',
                url: 'users/me'
            }).then(res => {
                if (res.status == 200) {
                    const userData = this.getters.authUser
                    userData.role = res.data.role;
                    userData.department = res.data.department;
                    userData.department_id = res.data.department_id;
                    userData.role_id = res.data.role_id;
                    userData.warehouseOptions = res.data.warehouseOptions;
                    userData.kn_warehouse_id = res.data.kn_warehouse_id;
                    userData.warehouse_confirmation_flag = res.data.warehouse_confirmation_flag;
                    const permissions = [...res.data.permissions];
                    const routePermissions = [];
                    const actionPermissions = [];
                    permissions.map(permission => {
                        routePermissions.push(permission.frontend_route_name);
                        actionPermissions.push(permission.permission_code);
                    });
                    context.commit('setUserData', { userData, permissions: { routePermissions, actionPermissions } })
                    localStorage.setItem('user', JSON.stringify(userData))
                    return true;
                }
            }).catch(() => {
                
            })
        },
        showError(context, payload) {
            context.commit('showError', payload);
            setTimeout(() => context.commit('showError'), 4000)
        },
        async setMfgSupplierData(context, payload) {
            if ('token' in payload && 'storeToken' in payload && payload.storeToken) {
                localStorage.setItem('auth_token', payload.token);
                localStorage.setItem('expires_at', payload.expiry);
                localStorage.setItem('token_type', 'bearer');
                localStorage.setItem('auth_type', 1);
                context.commit('setAuthData', {token: payload.token, tokenType: 'bearer', expiresAt: payload.expiry, authType: 1})
                // Set global header in axios for token authentication
                axios.defaults.headers.common['Authorization'] = `Bearer ${payload.token}`;
                // Store user information and permissions in the system
                const userData = {
                    name:payload.user.name,
                    id:payload.user.id,
                    initial:payload.user.initial
                }
                context.commit('setUserData', {userData})
                localStorage.setItem('user', JSON.stringify(userData))
            }
            return true;
        }
    },
}

export default authStore;