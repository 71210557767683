import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index'
import commonMixin from '@/mixin'

const routes = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      meta: { requiresAuth: true },
      redirect: 'dashboard'
    },
    {
      path: "/login",
      component: () => import("../views/auth/LoginView.vue"),
      name: "login",
      meta: { requiresAuth: false },
      beforeEnter() {
        if (!store.getters.isKinAuthenticated) {
          window.location.href = `${process.env.VUE_APP_AUTH_URL}/?return_url=${process.env.VUE_APP_URL}`;
        }
      },
    },
    {
      path: "/dashboard",
      component: () => import("../views/DashboardView.vue"),
      name: "dashboard",
      alias: "/",
      meta: { requiresAuth: true },
    },
    {
      path: "/locations",
      component: () => import("../views/location/LocationListView.vue"),
      name: "locations_list",
      meta: { requiresAuth: true },
    },
    {
      path: "/locations/:id",
      component: () => import("../views/location/LocationDetailView.vue"),
      name: "locations_detail",
      meta: { requiresAuth: true },
      props: true,
    },
    {
      path: "/suppliers",
      component: () => import("../views/supplier/SupplierListView.vue"),
      name: "suppliers_list",
      meta: { requiresAuth: true },
    },
    {
      path: "/suppliers/manual/inventory",
      component: () => import("../views/supplier/SupplierAddInventory.vue"),
      name: "suppliers_manual_inventory",
      meta: { requiresAuth: true },
    },
    {
      path: "/suppliers/:id",
      component: () => import("../views/supplier/SupplierDetailView.vue"),
      name: "suppliers_detail",
      meta: { requiresAuth: true },
      props: true,
    },
    {
      path: "/parts",
      component: () => import("../views/part/PartsListView.vue"),
      name: "parts_list",
      meta: { requiresAuth: true },
    },
    {
      path: "/part/:id",
      component: () => import("../views/part/PartDetailView.vue"),
      name: "parts_detail",
      meta: { requiresAuth: true },
      props: true,
    },
    {
      path: "/lots/:id",
      component: () => import("../views/part/PartLotDetailView.vue"),
      name: "lots_detail",
      meta: { requiresAuth: true },
      props: true,
    },
    {
      path: "/skids/:id",
      component: () => import("../views/part/PartSkidDetailView.vue"),
      name: "skids_detail",
      meta: { requiresAuth: true },
      props: true,
    },
    {
      path: "/boxes/:id",
      component: () => import("../views/part/PartBoxDetailView.vue"),
      name: "boxes_detail",
      meta: { requiresAuth: true },
      props: true,
    },
    {
      path: "/boms",
      component: () => import("../views/bom/BomsListView.vue"),
      name: "boms_list",
      meta: { requiresAuth: true },
    },
    {
      path: "/boms/:id",
      component: () => import("../views/bom/BomDetailView.vue"),
      name: "boms_detail",
      meta: { requiresAuth: true },
      props: true,
    },
    {
      path: "/inbound/shipment",
      component: () => import("../views/shipment/ShipmentListView.vue"),
      name: "bol_inbound_shipment_list",
      meta: { requiresAuth: true },
    },
    {
      path: "/inbound/shipment/:id",
      component: () => import("../views/shipment/ShipmentDetailView.vue"),
      name: "bol_inbound_shipment_detail",
      meta: { requiresAuth: true },
      props: true,
    },
    {
      path: "/outbound",
      component: () => import("../views/outbound/OutboundListView.vue"),
      name: "outbound_list",
      meta: { requiresAuth: true },
    },
    {
      path: "/outbound/:id",
      component: () => import("../views/outbound/OutboundDetailView.vue"),
      name: "outbound_detail",
      meta: { requiresAuth: true },
      props: true,
    },
    {
      path: "/inter-warehouses",
      component: () => import("../views/interWh/InterWhListView.vue"),
      name: "inter_wh_bol_list",
      meta: { requiresAuth: true },
    },
    {
      path: "/inter-warehouses/:id",
      component: () => import("../views/interWh/InterWhDetailView.vue"),
      name: "inter_wh_bol_detail",
      meta: { requiresAuth: true },
      props: true,
    },
    {
      path: "/inbound",
      component: () => import("../views/inbound/InboundListView.vue"),
      name: "inbound_list",
      meta: { requiresAuth: true },
    },
    {
      path: "/inbound/:id",
      component: () => import("../views/inbound/InboundDetailView.vue"),
      name: "inbound_detail",
      meta: { requiresAuth: true },
      props: true,
    },
    {
      path: "/inbound/customer-returns/:id",
      component: () => import("../views/inbound/InboundReturnDetailView.vue"),
      name: "inbound_return_detail",
      meta: { requiresAuth: true },
      props: true,
    },
    {
      path: "/inbound/simple-purchase/:id",
      component: () => import("../views/inbound/InboundSimplePurchaseDetailView.vue"),
      name: "inbound_simple_purchase_detail",
      meta: { requiresAuth: true },
      props: true,
    },
    {
      path: "/direct-bols",
      component: () => import("../views/direct/DirectListView.vue"),
      name: "direct_bol_list",
      meta: { requiresAuth: true },
    },
    {
      path: "/direct-bols/:id",
      component: () => import("../views/direct/DirectDetailView.vue"),
      name: "direct_bol_detail",
      meta: { requiresAuth: true },
      props: true,
    },
    {
      path: "/resupply",
      component: () => import("../views/resupply/ResupplyListView.vue"),
      name: "resupply_bol_list",
      meta: { requiresAuth: true },
    },
    {
      path: "/resupply/:id",
      component: () => import("../views/resupply/ResupplyDetailView.vue"),
      name: "resupply_bol_detail",
      meta: { requiresAuth: true },
      props: true,
    }, 
    {
      path: "/production-plans",
      component: () => import("../views/production/ProductionPlanListView.vue"),
      name: "production_plan_list",
      meta: { requiresAuth: true },
    },
    {
      path: "/production-plans/:id",
      component: () =>
        import("../views/production/ProductionPlanDetailView.vue"),
      name: "production_plan_detail",
      meta: { requiresAuth: true },
      props: true,
    },
    {
      path: "/purchase-orders",
      component: () =>
        import("../views/purchaseOrder/PurchaseOrderListView.vue"),
      name: "purchase_order_list",
      meta: { requiresAuth: true },
    },
    {
      path: "/purchase-orders/:id",
      component: () =>
        import("../views/purchaseOrder/PurchaseOrderDetailView.vue"),
      name: "purchase_order_detail",
      meta: { requiresAuth: true },
      props: true,
    },
    {
      path: "/mfg-invoices/:id",
      component: () =>
        import("../views/purchaseOrder/MfgInvoiceDetailView.vue"),
      name: "mfg_invoice_detail",
      meta: { requiresAuth: true },
      props: true,
    },
    {
      path: "/logout",
      name: "logout",
      component: () => import("../views/auth/LogoutView.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/print-label",
      name: "print_label",
      component: () => import("../views/PrintLabelView.vue"),
      meta: { requiresAuth: false },
    },
    {
        path: "/manufacturers/:initial",
        name: "manufacturer",
        component: () => import("../views/manufacturers/ManufacturerDashboardView.vue"),
        meta: { requiresAuth: false, mfgRoute: 1 },
        props: true,
    },
    {
      path: "/manufacturers/:initial/purchase-orders",
      name: "manufacturer_purchase_orders",
      component: () => import("../views/manufacturers/ManufacturerDashboardView.vue"),
      meta: { 
            requiresAuth: true, 
            mfgRoute: 1
        },
      props: true,
    },
    {
      path: "/:pathMatch(.*)*",
      component: () => import("../views/NotFoundView.vue"),
      name: "not_found",
    }
  ],
});
routes.beforeEach((to, from) => {
    const isMfgRoute = 'mfgRoute' in to.meta && to.meta.mfgRoute ? 1 : 0
    const fromMfgRoute = 'mfgRoute' in to.meta && from.meta.mfgRoute ? 1 : 0
    if (to.name == 'logout') {
        store.dispatch('logoutUser')
    }
    if (isMfgRoute) {
        if (store.getters.isAuthenticated && store.getters.authType == 0) {
            const url = routes.resolve({name: 'dashboard'}).href;
            window.location.href = url;
            return {name: 'dashboard'}
        }
        if (to.meta.requiresAuth && !store.getters.isMfgAuthenticated) {
            return {name: 'manufacturer', params: {initial: to.params.initial}}
        }
        if (to.name == 'manufacturer' && store.getters.isMfgAuthenticated) {
            return {name: 'manufacturer_purchase_orders', params: {initial: to.params.initial}}
        }
        // when KIN user enters MFG route redirect them back
        if (fromMfgRoute == 0 && from.path != '/') {
            return from
        }
        return true
    } else {
        // when MFG user enters KIN route redirect them back
        if (fromMfgRoute == 1) {
            return from
        }
        if (store.getters.isAuthenticated && store.getters.authType == 1) {
            const url = routes.resolve({name: 'manufacturer_purchase_orders', params: {initial: store.getters.authUser.initial}}).href;
            window.location.href = url;
            return {name: 'manufacturer_purchase_orders', params: {initial: store.getters.authUser.initial}}
        }
        if (to.meta.requiresAuth && !store.getters.isKinAuthenticated) {
            if (!('setToken' in to.query)) {
                return {name: 'login'}
            }
        }
        if (to.name == 'login' && store.getters.isKinAuthenticated) {
            return from
        }
        const isAllowed = commonMixin.methods.checkPermission(to.name);
        if (!isAllowed && to.name != 'print_label') {
            store.dispatch('showError', {title: 'Not allowed', body: 'You do not have appropriate permission to view the page', type: 'error'})
            return false;
        }
        return true
    }
})

routes.afterEach((to, from) => {
    if (to.name != from.name) {
        // reset the width of main body when redirected to other pages from detail page
        store.dispatch('general/setSidebarStatus', false);
    }
})

export default routes;