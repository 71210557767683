/* eslint-disable */ 
import { createApp } from 'vue'
import store from './store/index.js';
import './main.css';
import './assets/css/style.css';
import './assets/css/simptip.css';
import HighchartsVue from 'highcharts-vue';
import App from './App.vue'
import MfgApp from './ManufacturerApp.vue'
import routes from './routes/index.js';
import axiosConfig from './axiosConfig';
import CpBreadcrumb from '@/components/CpBreadcrumb.vue';
import PageLoaderComponent from '@/components/PageLoaderComponent.vue';
import constants from './constants/index.js';
import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';

const currentPath = window.location.pathname;
// console.log(routes.currentRoute.value)
let app;
if (currentPath.startsWith('/manufacturers/')) {
    app = createApp(MfgApp);
} else {
    app = createApp(App);
}
app.config.globalProperties.constants = constants;
app.use(store);
app.use(routes);
app.use(CkeditorPlugin)
app.component('CpBreadcrumb', CpBreadcrumb)
app.component('PageLoaderComponent', PageLoaderComponent)
app.use(HighchartsVue, {
    global: {
        config: {
            // Add the isCustomElement option
            compilerOptions: {
                isCustomElement: (tag) => tag === 'highcharts'
            }
        },
    }
});

app.config.unwrapInjectedRef = true
app.mount('#app');  