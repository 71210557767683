<template>
    <div>
        <div v-if="isPrintView">
            <PrintLabel />
        </div>
        <div v-else>
            <div v-if="loading" :class="{'absolute z-20 h-screen bg-trasperent top-0 left-0' : loading}" class="w-full py-8 px-4 flex flex-col justify-center items-center">
                <svg class="animate-spin -ml-1 mr-3 h-12 w-12 text-blue-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <div class="text-gray-600 text-lg mt-2 text-center">Logging in please wait...</div>
            </div>
            <div v-else>
                <DashboardLayoutComponent v-if="userAuth && userData && userData.id" />
                <AuthBaseLayout v-if="!userAuth" />
            </div>
            <AlertComponent v-if="showErrorObj" />
        </div>
    </div>
</template>

<script>
import commonMixin from './mixin';
import DashboardLayoutComponent from './views/layout/DashboardLayoutComponent.vue'
import AuthBaseLayout from './views/layout/auth/BaseLayout.vue'
import AlertComponent from '@/components/AlertComponent.vue'
import PrintLabel from './views/PrintLabelView.vue'
export default {
    name: 'App',
    mixins: [commonMixin],
    components: {
        DashboardLayoutComponent,
        AuthBaseLayout,
        AlertComponent,
        PrintLabel
    },
    data() {
        return {
        loading: false,
        }
    },
    mounted() {
        this.$router.isReady()
            .then(() => {
                if (
                    'setToken' in this.$route.query && 
                    this.$route.query.setToken
                ) {
                    this.loading = true;
                    const switchUser = 'switch_user' in this.$route.query && this.$route.query.switch_user == '1';
                    this.$store.dispatch('fetchUserData', {token: this.$route.query.setToken, storeToken: true, switchUser})
                        .then(res => {
                            if (res) {
                                this.$router.replace({name: 'dashboard'})
                                this.loading = false;
                            } else {
                                this.$router.replace({name: 'login'});
                                this.loading = false;
                            }
                        })
                        .catch(() => {
                            this.$router.replace({name: 'login'});
                            this.loading = false;
                        })
                } else if (this.userAuth) {
                    this.$store.dispatch('checkAuth');
                }
        }).catch(() => {
            console.log('router not ready')
        })
    },
    methods: {
    },
    computed: {
        isPrintView() {
            return this.$route.name == 'print_label'
        },
        showErrorObj() {
            const errorObj = this.$store.getters.errorObj;
            return errorObj.title || errorObj.body; 
        }
    }
}
</script>

<style>
</style>
